import { jsx, ThemeProvider } from 'theme-ui';
import BlogCard from '@components/BlogCard';
import InlineCode from '@components/InlineCode';
import LiveEditableCode from '@components/LiveEditableCode';
import TextLink from '@components/TextLink';
import { theme, Box, Label, Text, TextInput } from '@components/Box';
import cssPropComparisonExample from "../../../../src/pages/posts/the-box/code/css-prop-comparison.example";
import boxComparisonExample from "../../../../src/pages/posts/the-box/code/box-comparison.example";
import applicationExample from "../../../../src/pages/posts/the-box/code/application.example";
import * as React from 'react';
export default {
  jsx,
  ThemeProvider,
  BlogCard,
  InlineCode,
  LiveEditableCode,
  TextLink,
  theme,
  Box,
  Label,
  Text,
  TextInput,
  cssPropComparisonExample,
  boxComparisonExample,
  applicationExample,
  React
};
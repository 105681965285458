// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-mdx": () => import("./../../../src/pages/about/index.mdx" /* webpackChunkName: "component---src-pages-about-index-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-posts-conditional-gitconfig-index-mdx": () => import("./../../../src/pages/posts/conditional-gitconfig/index.mdx" /* webpackChunkName: "component---src-pages-posts-conditional-gitconfig-index-mdx" */),
  "component---src-pages-posts-dark-theme-with-stitches-index-mdx": () => import("./../../../src/pages/posts/dark-theme-with-stitches/index.mdx" /* webpackChunkName: "component---src-pages-posts-dark-theme-with-stitches-index-mdx" */),
  "component---src-pages-posts-index-tsx": () => import("./../../../src/pages/posts/index.tsx" /* webpackChunkName: "component---src-pages-posts-index-tsx" */),
  "component---src-pages-posts-minimal-xstate-index-mdx": () => import("./../../../src/pages/posts/minimal-xstate/index.mdx" /* webpackChunkName: "component---src-pages-posts-minimal-xstate-index-mdx" */),
  "component---src-pages-posts-replacing-sass-audio-tsx": () => import("./../../../src/pages/posts/replacing-sass/Audio.tsx" /* webpackChunkName: "component---src-pages-posts-replacing-sass-audio-tsx" */),
  "component---src-pages-posts-replacing-sass-index-mdx": () => import("./../../../src/pages/posts/replacing-sass/index.mdx" /* webpackChunkName: "component---src-pages-posts-replacing-sass-index-mdx" */),
  "component---src-pages-posts-speeding-up-storybook-index-mdx": () => import("./../../../src/pages/posts/speeding-up-storybook/index.mdx" /* webpackChunkName: "component---src-pages-posts-speeding-up-storybook-index-mdx" */),
  "component---src-pages-posts-sx-prop-index-mdx": () => import("./../../../src/pages/posts/sx-prop/index.mdx" /* webpackChunkName: "component---src-pages-posts-sx-prop-index-mdx" */),
  "component---src-pages-posts-the-box-index-mdx": () => import("./../../../src/pages/posts/the-box/index.mdx" /* webpackChunkName: "component---src-pages-posts-the-box-index-mdx" */),
  "component---src-pages-work-index-mdx": () => import("./../../../src/pages/work/index.mdx" /* webpackChunkName: "component---src-pages-work-index-mdx" */)
}

